// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2024/2/2
// +-------------------------------------------------------------
// |Time: 10:24
// +-------------------------------------------------------------
/**
 *
 * store.$reset() ：
 * 可以调用store上的$reset()方法将状态重置到其初始值
 *
 * store.$patch
 * 使用部分‘state’对象同时应该多个修改
 *
 * store.$state
 *可以通过将其$state属性设置为新对象替换Store的整个状态
 *
 * store.$subscribe
 * 可以通过$subscribe监测到store数据变化
 *
 *
 */
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const store = createPinia();
store.use(piniaPluginPersistedstate);


export default store;
