// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2024/2/22
// +-------------------------------------------------------------
// |Time: 15:10
// +-------------------------------------------------------------
import {createRouter, createWebHistory} from 'vue-router'
import User from "@/router/module/user";

const routes = [
    // 支付
    {
        path: '/pay/pay-spend',
        name: 'PaySpendOrder',
        component: () => import('@/views/Pay/Spend/Index')
    },
    //用户中心
    {
        path: '/game/major-pane',
        name: 'MajorPane',
        component: () => import('@/views/MajorPane/Index')
    },
    {
        path: "/game/aid_:app_id",
        name: "Game",
        component: () => import("../views/Play/Game")
    },
    {
        path: "/game/aid_:app_id/cid_:channel",
        name: "PlayGame",
        component: () => import("../views/Play/Game")
    },
    //测试地址
    {
        path: "/demo",
        name: "TestDemo",
        component: () => import('../views/Others/Demo')
    },
    // 错误路由
    {
        path: '/:pathMatch(.*)*',
        name: '404Page',
        component: () => import('../views/Others/404.vue')
    },
    ...User
]

const router = createRouter({
    history: createWebHistory(),
    routes,

});


export default router
