import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {ConfigProvider} from "vant";
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
// 桌面端适配 在桌面端自动将 mouse 事件转换成对应的 touch 事件
import '@vant/touch-emulator';
import './assets/style/property.css'
import 'lib-flexible'
createApp(App).use(router).use(store).use(ConfigProvider).mount('#app')
