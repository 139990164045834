// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2024/7/6
// +-------------------------------------------------------------
// |Time: 14:39
// +-------------------------------------------------------------
export default [
    {
        path: '/user/user-center',
        name: "UserCenter",
        meta: {
            title: "用户中心"
        },
        component: () => import("@/views/MajorPane/User/Index")
    },
    {
        path: '/user/order-list',
        name: "OrderList",
        meta: {
            title: "订单记录"
        },
        component: () => import("@/views/MajorPane/User/OrderList.vue")
    },
    {
        path: '/user/bind',
        name: "UserBind",
        meta: {
            title: "用户平台币"
        },
        component: () => import("@/views/MajorPane/User/Bind.vue")
    }
]
